<template>
  <section>
    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
      <b-card>
        <div
          v-if="empresaSelecionada"
          class="d-flex align-items-center mb-1 min-height-fazer-outra-busca"
        >
          <a
            class="d-flex align-items-center purple-text-color"
            @click="retornaPesquisa()"
          >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              size="14"
              class="custom-spacement"
            />
            <div class="custom-font-find-another-business">Fazer outra busca</div>
          </a>
        </div>
        <div class="organizador-questionario-nome-empresa">
          <div class="pr-1 mb-1">
            <div v-if="questionariosSelect.length > 1" class="d-block d-sm-flex align-items-sm-center">
              <div class="titulo mr-1">
                Selecionar um questionário
              </div>
              <v-select
                v-model="questionarioSelecionado"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="questionariosSelect"
                :clearable="false"
                class="custom-select-business mt-1 mb-1 mt-sm-0 mb-sm-0"
                label="descricao"
                item-text="descricao"
                item-value="descricao"
                :reduce="option => option.descricao"
                @input="()=> acessoDashboard = true"
              >
                <span slot="no-options">Nenhum questionário encontrado.</span>
              </v-select>
            </div>
            <div class="subtitulo">
              Selecionado: {{ questionarioSelecionado }}
            </div>
          </div>
          <div v-if="empresaSelecionada">
            <div class="d-flex custom-font-business-name">
              <span class="custom-spacement">Painel</span>
              <a @click.once="buscaDadosRedireciona()"><span class="purple-text-color">{{empresaSelecionada.razaoCnpjEmpresa}}</span></a>
            </div>
          </div>
        </div>
      </b-card>
      <div v-if="acessoDashboard">
        <div v-if="questionarioSelecionado === 'PHQ-9'">
          <dashboard-phq
            :empresaSelecionada="empresaSelecionada"
            @retorna-listagem-empresa="retornarFiltragem"
            :dadosFullScreen="dadosFullScreen"
          :rotaDeRedirecionamento="rotaDeRedirecionamento"
        />
        </div>
        <div v-if="questionarioSelecionado === 'DASS-21'">
          <dashboard-dass
            :idEmpresa="idEmpresa"
            :dadosFullScreen="dadosFullScreen"
            :rotaDeRedirecionamento="rotaDeRedirecionamento"
            @retorna-listagem-empresa="retornarFiltragem"
          />
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import { BCard, BCol, BButton, BRow, BOverlay } from 'bootstrap-vue'
import vSelect from "vue-select"
import DashboardPhq from "@/views/pages/dashboard/saude-mental/phq-9/Dashboard.vue";
import DashboardDass from "@/views/pages/dashboard/saude-mental/dass-21/Dashboard.vue";
import { getUserData } from "@/auth/utils";
import { CHAVES_MODULOS } from "@/custom-enum/modulos-enum.js";
import { modalGenericModel } from "@/libs/sweetalerts";

export default {
  components: {
    BCard,
    BCol,
    BButton,
    BRow,
    BOverlay,
    vSelect,
    DashboardPhq,
    DashboardDass
  },

  props: {
    empresaSelecionada: {
      type: Object,
      required: false
    },
    dadosFullScreen: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      idEmpresa: null,
      idEmpresaGrupoConsulta: null,
      acessoDashboard: false,
      rotaDeRedirecionamento: null,
      questionarioSelecionado: null,
      questionariosSelect: [],
      loadingShow: true,
      dadosQuestionarios: [],
    };
  },

  async mounted() {
    this.preparaIdEmpresa()
    await this.retornaEmpresaGrupo()
    await this.verificaDadosQuestionariosSaudeMental()
    await this.preencheSelectQuestionarios();
   
  },

  methods: {
    preparaIdEmpresa() {
      if(
        typeof this.$props.empresaSelecionada !== 'undefined'
      ){
        this.rotaDeRedirecionamento = "painel-empresa-saude-mental-tela-cheia"
        this.idEmpresa = this.$props.empresaSelecionada.id_empresa
      }else{
        const usuario = getUserData();
        if (!usuario?.empresa.id_empresa) {
          this.$router.push({ name: "error-404" });
        }
        this.rotaDeRedirecionamento = "saude-mental-painel-tela-cheia"
        this.idEmpresa = usuario.empresa.id_empresa
      }
    },

    async verificaDadosQuestionariosSaudeMental(){
      if(this.idEmpresaGrupoConsulta !== null){
        await this.$http
        .get(this.$api.VerificaQuestionariosSaudeMental(this.idEmpresa, this.idEmpresaGrupoConsulta))
        .then((res) => {
          if(res.status == 200){
            this.dadosQuestionarios = res.data
          }
        })
      }
      
    },
    async preencheSelectQuestionarios(){
      let params = {
        id_empresa: this.idEmpresa,
        chave_modulo: CHAVES_MODULOS.SAUDE_MENTAL
      };
      await this.$http
      .get(this.$api.QuestionariosPorEmpresaECiclos, { params })
      .then((res) => {
        if (res.status == 204 || res.data.length == 0) {
          this.modalRetornoEmpresaSemQuestionarios()
        }else{
          let dados = res.data.sort((a, b) => b.id_questionario - a.id_questionario)
          this.questionariosSelect = this.verificaQualQuestionarioMostrarPrimeiro(dados);
          this.acessoDashboard = true
          this.verificaDashboardFullScreen()
          this.loadingShow = false
        }
      })
      .catch(() => {
        this.$router.push({ name: "error-404" });
      });
    },

    async retornaEmpresaGrupo(){
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.RetornaEmpresaGrupo,
        "{idEmpresa}",
        this.idEmpresa,
      );
     await this.$http
      .get(rota)
      .then((res) => {
        if(res.data[0]){
          this.idEmpresaGrupoConsulta = res.data[0].id_empresa_grupo
        }
      })
    },

    retornarFiltragem()
    {
      if(this.questionariosSelect.length > 1){
        this.acessoDashboard = false;
      }else{
        this.retornaPesquisa();
      }
    },
    retornaPesquisa(){
      this.$emit('redirecionamento-filtragem');
    },
    buscaDadosRedireciona() {
      let rota = this.$api.EmpresasId(this.idEmpresa);

      this.$http.get(rota).then((res) => {
        const relacionamentoGrupoMatriz = res.data.relacionamento_grupo_matriz
        const relacionamentoGrupoFilial = res.data.relacionamento_grupo_filiais

        if(relacionamentoGrupoMatriz) {
          this.idEmpresaGrupo = relacionamentoGrupoMatriz.id_empresa_grupo
        }

        if(relacionamentoGrupoFilial) {
          this.idEmpresaGrupo = relacionamentoGrupoFilial.id_empresa_grupo
        }

        this.redirecionarParaMatrizOuFilial(
          this.idEmpresa,
          res.data.id_tipo_empresa,
          this.idEmpresaGrupo
        );
      })
    },
    redirecionarParaMatrizOuFilial(idEmpresa, idTipoEmpresa, idEmpresaGrupo){
      let rota = '';

      if(idTipoEmpresa === 1) {
          rota = `/grupo/${idEmpresaGrupo}/matriz`;
      }

      if(idTipoEmpresa === 2) {
          rota = `/grupo/${idEmpresaGrupo}/filiais/${idEmpresa}`;
      }

      this.$router.push({ path: rota })
    },
    verificaDashboardFullScreen(){
      if(
        typeof this.$props.dadosFullScreen !== 'undefined' &&
        typeof this.$props.empresaSelecionada !== 'undefined' &&
        this.$props.dadosFullScreen.idEmpresa === this.$props.empresaSelecionada.id_empresa
      ){
        this.questionarioSelecionado = this.$props.dadosFullScreen.nomeQuestionario
      }else if(this.$route.params.preSelecaoQuestionario){
        this.questionarioSelecionado = this.$route.params.preSelecaoQuestionario
        this.$route.params.preSelecaoQuestionario = null
      }else {
       this.questionarioSelecionado = this.questionariosSelect[0].descricao
      }
    },
    verificaQualQuestionarioMostrarPrimeiro(dados){
      if(this.idEmpresaGrupoConsulta !== null){
        if(this.dadosQuestionarios.length === 1 && dados.length > 1){
          return this.dadosQuestionarios.some(item => item.descricao === 'DASS-21') ? dados.slice().reverse() : dados
        }
      }
      return dados
    },
     modalRetornoEmpresaSemQuestionarios(){
      modalGenericModel(
        'Resultados não encontrados',
        'A empresa buscada não possui resultados para este módulo',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Entendi'
      ).then(() => {
        if(
          typeof this.$props.empresaSelecionada !== 'undefined'
        ){
          this.$emit('redirecionamento-filtragem')
        }else{
          this.$router.push(
            {
              name: 'bem-vindo'
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
  .card{
    margin-bottom: 15px;
  }
  .custom-font-find-another-business{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
  .custom-font-business-name{
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
  }
  .purple-text-color{
    color: #7030A0;
  }
  .custom-spacement{
    margin-right: 8px;
  }
  .min-height-fazer-outra-busca{
    min-height: 20px;
  }
  .organizador-questionario-nome-empresa{
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    margin-bottom: -10px;
  }
  .titulo{
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .subtitulo{
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
  }
  .custom-select-business{
    min-width: 180px;
  }
  .custom-select-business::v-deep .vs__dropdown-toggle{
    border: 1px solid #7030A0;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }
  .custom-select-business::v-deep .vs__selected-options{
    padding: 0;
    flex-basis: inherit;
    flex-grow: initial;
  }
  .custom-select-business::v-deep .vs__search{
    margin: 0;
    padding: 0;
    max-height: 0;
    max-width: 0;
  }
  .custom-select-business::v-deep .vs__selected{
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #7030A0 !important;
    padding-left: 10px;
  }
  .custom-select-business.vs--single.vs--open::v-deep .vs__selected{
    position: relative;
  }
  .custom-select-business::v-deep .feather-chevron-down{
    color: #7030A0;
  }
</style>
