<template>
  <div>
    <div
      v-if="dados && dados.length > 0"
    >
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <div
        v-if="!loadingShow"
        class="height-custom px-2 mt-1 pb-md-0 pb-1"
      >
        <b-row
          class="no-gutters"
        >
          <b-col
            cols="6"
            sm="3"
            class="pr-1"
          >
            <div
              class="genderTitle mb-2"
              v-b-tooltip.bottomright="{
                customClass: 'custom-tooltip',
                title: tooltipFeminino ? textTooltip : ''
              }"
            >
              {{ dadosOrganizados[0]['nomeGenero'] }}
            </div>
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[0]['doencas'][0].doenca"
              :barAbsoluteNumber="dadosOrganizados[0]['doencas'][0].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[0]['doencas'][0].totalDoenca, dadosOrganizados[0]['doencas'][0].totalResposta)"
              :barColor="'#2772C0'"
              :refKey="1"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[0]['doencas'][1].doenca"
              :barAbsoluteNumber="dadosOrganizados[0]['doencas'][1].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[0]['doencas'][1].totalDoenca, dadosOrganizados[0]['doencas'][1].totalResposta)"
              :barColor="'#002060'"
              :refKey="2"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[0]['doencas'][2].doenca"
              :barAbsoluteNumber="dadosOrganizados[0]['doencas'][2].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[0]['doencas'][2].totalDoenca, dadosOrganizados[0]['doencas'][2].totalResposta)"
              :barColor="'#7030A0'"
              :refKey="3"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[0]['doencas'][3].doenca"
              :barAbsoluteNumber="dadosOrganizados[0]['doencas'][3].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[0]['doencas'][3].totalDoenca, dadosOrganizados[0]['doencas'][3].totalResposta)"
              :barColor="'#36BA00'"
              :refKey="4"
            />
          </b-col>
          <b-col
            cols="6"
            sm="3"
            class="pl-1"
          >
            <div
              class="genderTitle mb-2"
              v-b-tooltip.bottomright="{
                 placement: 'left',
                customClass: 'custom-tooltip',
                title: tooltipMasculino ? textTooltip : ''
              }"
            >
              {{ dadosOrganizados[1]['nomeGenero'] }}
            </div>
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[1]['doencas'][0].doenca"
              :barAbsoluteNumber="dadosOrganizados[1]['doencas'][0].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[1]['doencas'][0].totalDoenca, dadosOrganizados[1]['doencas'][0].totalResposta)"
              :barColor="'#2772C0'"
              :refKey="1"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[1]['doencas'][1].doenca"
              :barAbsoluteNumber="dadosOrganizados[1]['doencas'][1].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[1]['doencas'][1].totalDoenca, dadosOrganizados[1]['doencas'][1].totalResposta)"
              :barColor="'#002060'"
              :refKey="2"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[1]['doencas'][2].doenca"
              :barAbsoluteNumber="dadosOrganizados[1]['doencas'][2].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[1]['doencas'][2].totalDoenca, dadosOrganizados[1]['doencas'][2].totalResposta)"
              :barColor="'#7030A0'"
              :refKey="3"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[1]['doencas'][3].doenca"
              :barAbsoluteNumber="dadosOrganizados[1]['doencas'][3].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[1]['doencas'][3].totalDoenca, dadosOrganizados[1]['doencas'][3].totalResposta)"
              :barColor="'#36BA00'"
              :refKey="4"
            />
          </b-col>
          <b-col
            cols="6"
            sm="3"
            class="pl-sm-1 mt-1 mt-sm-0"
          >
            <div
              class="genderTitle mb-2"
              v-b-tooltip.bottomright="{
                placement: 'left',
                customClass: 'custom-tooltip',
                title: tooltipDemaisGeneros ? textTooltip : ''
              }"
            >
              {{ dadosOrganizados[3]['nomeGenero'] }}
            </div>
              <respostas-por-genero-titulo-grafico
                :barTitle="dadosOrganizados[3]['doencas'][0].doenca"
                :barAbsoluteNumber="dadosOrganizados[3]['doencas'][0].totalDoenca"
                :barPercentage="preparePorcentage(dadosOrganizados[3]['doencas'][0].totalDoenca, dadosOrganizados[3]['doencas'][0].totalResposta)"
                :barColor="'#2772C0'"
                :refKey="1"
                class="mb-1"
              />
              <respostas-por-genero-titulo-grafico
                :barTitle="dadosOrganizados[3]['doencas'][1].doenca"
                :barAbsoluteNumber="dadosOrganizados[3]['doencas'][1].totalDoenca"
                :barPercentage="preparePorcentage(dadosOrganizados[3]['doencas'][1].totalDoenca, dadosOrganizados[3]['doencas'][1].totalResposta)"
                :barColor="'#002060'"
                :refKey="2"
                class="mb-1"
              />
              <respostas-por-genero-titulo-grafico
                :barTitle="dadosOrganizados[3]['doencas'][2].doenca"
                :barAbsoluteNumber="dadosOrganizados[3]['doencas'][2].totalDoenca"
                :barPercentage="preparePorcentage(dadosOrganizados[3]['doencas'][2].totalDoenca, dadosOrganizados[3]['doencas'][2].totalResposta)"
                :barColor="'#7030A0'"
                :refKey="3"
                class="mb-1"
              />
              <respostas-por-genero-titulo-grafico
                :barTitle="dadosOrganizados[3]['doencas'][3].doenca"
                :barAbsoluteNumber="dadosOrganizados[3]['doencas'][3].totalDoenca"
                :barPercentage="preparePorcentage(dadosOrganizados[3]['doencas'][3].totalDoenca, dadosOrganizados[3]['doencas'][3].totalResposta)"
                :barColor="'#36BA00'"
                :refKey="4"
              />
          </b-col>

          <b-col
            cols="6"
            sm="3"
            class="pl-1 mt-1 mt-sm-0"
          >
            <div
              class="genderTitle mb-2"
              v-b-tooltip.bottomright="{
                customClass: 'custom-tooltip',
                title: tooltipNaoInformado? textTooltip : ''
              }"
            >
              {{ dadosOrganizados[2]['nomeGenero'] }}
            </div>
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[2]['doencas'][0].doenca"
              :barAbsoluteNumber="dadosOrganizados[2]['doencas'][0].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[2]['doencas'][0].totalDoenca, dadosOrganizados[2]['doencas'][0].totalResposta)"
              :barColor="'#2772C0'"
              :refKey="1"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[2]['doencas'][1].doenca"
              :barAbsoluteNumber="dadosOrganizados[2]['doencas'][1].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[2]['doencas'][1].totalDoenca, dadosOrganizados[2]['doencas'][1].totalResposta)"
              :barColor="'#002060'"
              :refKey="2"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[2]['doencas'][2].doenca"
              :barAbsoluteNumber="dadosOrganizados[2]['doencas'][2].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[2]['doencas'][2].totalDoenca, dadosOrganizados[2]['doencas'][2].totalResposta)"
              :barColor="'#7030A0'"
              :refKey="3"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[2]['doencas'][3].doenca"
              :barAbsoluteNumber="dadosOrganizados[2]['doencas'][3].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[2]['doencas'][3].totalDoenca, dadosOrganizados[2]['doencas'][3].totalResposta)"
              :barColor="'#36BA00'"
              :refKey="4"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  VBTooltip
} from "bootstrap-vue";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";
import RespostasPorGeneroTituloGrafico from "@/views/components/custom/dashboard-saude-mental/dass-21/RespostasPorGeneroTituloGrafico.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    CardSemDados,
    RespostasPorGeneroTituloGrafico
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      loadingShow: true,
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      },
      tooltipMasculino: true,
      tooltipFeminino: true,
      tooltipNaoInformado: true,
      tooltipDemaisGeneros: true,
      textTooltip: 'Ainda não há dados suficientes para serem exibidos no painel',
      dadosOrganizados: [
        {
          'nomeGenero': 'Feminino',
          'doencas': [
            {
              'doenca': 'Ansiedade',
              'totalDoenca': 0,
              'totalResposta': 0
            },
            {
              'doenca': 'Depressão',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Estresse',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Sem sintomas',
              'totalDoenca': 0,
              'totalResposta': 0
            }
          ]
        },
        {
          'nomeGenero': 'Masculino',
          'doencas': [
            {
              'doenca': 'Ansiedade',
              'totalDoenca': 0,
              'totalResposta': 0
            },
            {
              'doenca': 'Depressão',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Estresse',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Sem sintomas',
              'totalDoenca': 0,
              'totalResposta': 0
            }
          ]
        },
        {
          'nomeGenero': 'Não identificado',
          'doencas': [
            {
              'doenca': 'Ansiedade',
              'totalDoenca': 0,
              'totalResposta': 0
            },
            {
              'doenca': 'Depressão',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Estresse',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Sem sintomas',
              'totalDoenca': 0,
              'totalResposta': 0
            }
          ]
        },
        {
          'nomeGenero': 'Demais gêneros',
          'doencas': [
            {
              'doenca': 'Ansiedade',
              'totalDoenca': 0,
              'totalResposta': 0
            },
            {
              'doenca': 'Depressão',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Estresse',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Sem sintomas',
              'totalDoenca': 0,
              'totalResposta': 0
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.organizaDados()
    this.verificaERetornaTooltip()
  },
  methods: {
    organizaDados(){
      for (const element of this.$props.dados) {
        if(element.genero === 'Feminino'){
          this.organizaDadosDoencas(0, element)
        }else if(element.genero === 'Masculino'){
          this.organizaDadosDoencas(1, element)
        }else if(element.genero === 'Prefiro não informar'){
          this.organizaDadosDoencas(2, element)
        }else if(element.genero === 'Não me identifico com as opções'){
          this.organizaDadosDoencas(3, element)
        }
         
        
      }
      this.loadingShow = false
    },
    organizaDadosDoencas(indexArrayValue, arrayElement){
      if(arrayElement.doenca === 'Ansiedade'){
        this.organizaNumerosDoencas(
          this.dadosOrganizados[indexArrayValue]['doencas'][0],
          arrayElement
        )
      }else if(arrayElement.doenca === 'Depressão'){
        this.organizaNumerosDoencas(
          this.dadosOrganizados[indexArrayValue]['doencas'][1],
          arrayElement
        )
      }else if(arrayElement.doenca === 'Estresse'){
        this.organizaNumerosDoencas(
          this.dadosOrganizados[indexArrayValue]['doencas'][2],
          arrayElement
        )
      }else if(arrayElement.doenca === 'Sem Sintomas'){
        this.organizaNumerosDoencas(
          this.dadosOrganizados[indexArrayValue]['doencas'][3],
          arrayElement
        )
      }
    },

    verificaERetornaTooltip(){
      if(this.$props.dados.length !== 0){
        this.tooltipMasculino = true
        this.tooltipFeminino = true
        this.tooltipNaoInformado = true 
        this.tooltipDemaisGeneros = true

        this.$props.dados.map((item) => {
          if(item.genero === 'Masculino'){
            this.tooltipMasculino = false
          }else if(item.genero === 'Feminino'){
            this.tooltipFeminino = false
          }else if(item.genero === 'Prefiro não informar'){
            this.tooltipNaoInformado = false              
          }else{
            this.tooltipDemaisGeneros = false
          }
        })
        
      }
    },
    organizaNumerosDoencas(arrayDoenca, arrayElement){
      arrayDoenca.totalDoenca = arrayElement.total_doenca
      arrayDoenca.totalResposta = arrayElement.total_resposta
    },
    preparePorcentage(value1, value2){
      return ((value1/value2) * 100).toFixed(2).toString();
    }
  }
};
</script>
<style scoped>
.height-custom{
  height: 252px;
}
.genderTitle{
  max-width:125px ;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
}
@media (max-width: 767px) {
  .height-custom{
    height: 100%;
    margin-bottom: 1rem;
  }
}
@media (max-width: 400px) {
  .genderTitle{
    font-size: 12px;
  }
}
</style>
