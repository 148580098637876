var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dados && _vm.dados.length > 0)?_c('div',[(_vm.loadingShow)?_c('b-overlay',{staticClass:"mt-2",attrs:{"show":_vm.loadingShow,"rounded":"lg","opacity":"0.6"}}):_vm._e(),(!_vm.loadingShow)?_c('div',{staticClass:"height-custom px-2 mt-1 pb-md-0 pb-1"},[_c('b-row',{staticClass:"no-gutters"},[_c('b-col',{staticClass:"pr-1",attrs:{"cols":"6","sm":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottomright",value:({
              customClass: 'custom-tooltip',
              title: _vm.tooltipFeminino ? _vm.textTooltip : ''
            }),expression:"{\n              customClass: 'custom-tooltip',\n              title: tooltipFeminino ? textTooltip : ''\n            }",modifiers:{"bottomright":true}}],staticClass:"genderTitle mb-2"},[_vm._v(" "+_vm._s(_vm.dadosOrganizados[0]['nomeGenero'])+" ")]),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[0]['doencas'][0].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[0]['doencas'][0].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[0]['doencas'][0].totalDoenca, _vm.dadosOrganizados[0]['doencas'][0].totalResposta),"barColor":'#2772C0',"refKey":1}}),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[0]['doencas'][1].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[0]['doencas'][1].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[0]['doencas'][1].totalDoenca, _vm.dadosOrganizados[0]['doencas'][1].totalResposta),"barColor":'#002060',"refKey":2}}),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[0]['doencas'][2].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[0]['doencas'][2].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[0]['doencas'][2].totalDoenca, _vm.dadosOrganizados[0]['doencas'][2].totalResposta),"barColor":'#7030A0',"refKey":3}}),_c('respostas-por-genero-titulo-grafico',{attrs:{"barTitle":_vm.dadosOrganizados[0]['doencas'][3].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[0]['doencas'][3].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[0]['doencas'][3].totalDoenca, _vm.dadosOrganizados[0]['doencas'][3].totalResposta),"barColor":'#36BA00',"refKey":4}})],1),_c('b-col',{staticClass:"pl-1",attrs:{"cols":"6","sm":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottomright",value:({
               placement: 'left',
              customClass: 'custom-tooltip',
              title: _vm.tooltipMasculino ? _vm.textTooltip : ''
            }),expression:"{\n               placement: 'left',\n              customClass: 'custom-tooltip',\n              title: tooltipMasculino ? textTooltip : ''\n            }",modifiers:{"bottomright":true}}],staticClass:"genderTitle mb-2"},[_vm._v(" "+_vm._s(_vm.dadosOrganizados[1]['nomeGenero'])+" ")]),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[1]['doencas'][0].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[1]['doencas'][0].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[1]['doencas'][0].totalDoenca, _vm.dadosOrganizados[1]['doencas'][0].totalResposta),"barColor":'#2772C0',"refKey":1}}),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[1]['doencas'][1].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[1]['doencas'][1].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[1]['doencas'][1].totalDoenca, _vm.dadosOrganizados[1]['doencas'][1].totalResposta),"barColor":'#002060',"refKey":2}}),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[1]['doencas'][2].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[1]['doencas'][2].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[1]['doencas'][2].totalDoenca, _vm.dadosOrganizados[1]['doencas'][2].totalResposta),"barColor":'#7030A0',"refKey":3}}),_c('respostas-por-genero-titulo-grafico',{attrs:{"barTitle":_vm.dadosOrganizados[1]['doencas'][3].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[1]['doencas'][3].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[1]['doencas'][3].totalDoenca, _vm.dadosOrganizados[1]['doencas'][3].totalResposta),"barColor":'#36BA00',"refKey":4}})],1),_c('b-col',{staticClass:"pl-sm-1 mt-1 mt-sm-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottomright",value:({
              placement: 'left',
              customClass: 'custom-tooltip',
              title: _vm.tooltipDemaisGeneros ? _vm.textTooltip : ''
            }),expression:"{\n              placement: 'left',\n              customClass: 'custom-tooltip',\n              title: tooltipDemaisGeneros ? textTooltip : ''\n            }",modifiers:{"bottomright":true}}],staticClass:"genderTitle mb-2"},[_vm._v(" "+_vm._s(_vm.dadosOrganizados[3]['nomeGenero'])+" ")]),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[3]['doencas'][0].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[3]['doencas'][0].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[3]['doencas'][0].totalDoenca, _vm.dadosOrganizados[3]['doencas'][0].totalResposta),"barColor":'#2772C0',"refKey":1}}),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[3]['doencas'][1].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[3]['doencas'][1].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[3]['doencas'][1].totalDoenca, _vm.dadosOrganizados[3]['doencas'][1].totalResposta),"barColor":'#002060',"refKey":2}}),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[3]['doencas'][2].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[3]['doencas'][2].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[3]['doencas'][2].totalDoenca, _vm.dadosOrganizados[3]['doencas'][2].totalResposta),"barColor":'#7030A0',"refKey":3}}),_c('respostas-por-genero-titulo-grafico',{attrs:{"barTitle":_vm.dadosOrganizados[3]['doencas'][3].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[3]['doencas'][3].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[3]['doencas'][3].totalDoenca, _vm.dadosOrganizados[3]['doencas'][3].totalResposta),"barColor":'#36BA00',"refKey":4}})],1),_c('b-col',{staticClass:"pl-1 mt-1 mt-sm-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottomright",value:({
              customClass: 'custom-tooltip',
              title: _vm.tooltipNaoInformado? _vm.textTooltip : ''
            }),expression:"{\n              customClass: 'custom-tooltip',\n              title: tooltipNaoInformado? textTooltip : ''\n            }",modifiers:{"bottomright":true}}],staticClass:"genderTitle mb-2"},[_vm._v(" "+_vm._s(_vm.dadosOrganizados[2]['nomeGenero'])+" ")]),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[2]['doencas'][0].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[2]['doencas'][0].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[2]['doencas'][0].totalDoenca, _vm.dadosOrganizados[2]['doencas'][0].totalResposta),"barColor":'#2772C0',"refKey":1}}),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[2]['doencas'][1].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[2]['doencas'][1].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[2]['doencas'][1].totalDoenca, _vm.dadosOrganizados[2]['doencas'][1].totalResposta),"barColor":'#002060',"refKey":2}}),_c('respostas-por-genero-titulo-grafico',{staticClass:"mb-1",attrs:{"barTitle":_vm.dadosOrganizados[2]['doencas'][2].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[2]['doencas'][2].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[2]['doencas'][2].totalDoenca, _vm.dadosOrganizados[2]['doencas'][2].totalResposta),"barColor":'#7030A0',"refKey":3}}),_c('respostas-por-genero-titulo-grafico',{attrs:{"barTitle":_vm.dadosOrganizados[2]['doencas'][3].doenca,"barAbsoluteNumber":_vm.dadosOrganizados[2]['doencas'][3].totalDoenca,"barPercentage":_vm.preparePorcentage(_vm.dadosOrganizados[2]['doencas'][3].totalDoenca, _vm.dadosOrganizados[2]['doencas'][3].totalResposta),"barColor":'#36BA00',"refKey":4}})],1)],1)],1):_vm._e()],1):_c('div',{staticClass:"height-custom d-flex align-items-center justify-content-center"},[_c('card-sem-dados',{attrs:{"mensagemTipo":_vm.validarCardSemDados.tipagemErro}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }