<template>
  <div>
    <div
      v-if="dados[0].dados.length > 0"
      class="px-2"
    >
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow" 
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <b-row
        class="no-gutters"
      >
        <b-col
          sm="8"
          cols="12"
          class="pr-sm-1 pr-0 mb-1"
        >
          <vue-perfect-scrollbar
            class="height-custom"
            :settings="settings"
          >
            <div
              v-if="Object.keys(dadosSelecionados).length !== 0"
            >
              <div
                v-if="opcaoSelecionada === 'Total'"
                class="d-flex align-items-center justify-content-center height-custom" 
              >
                <div class="minimal-graph-size-pizza">
                  <detalhamento-por-area-grafico-pizza
                    :key="childKey"
                    :dados="dadosSelecionados.dados"
                  />
                </div>
              </div>
              <div
                v-else
              >
                <detalhamento-por-area-grafico
                  :dados="dadosSelecionados"
                  :key="childKey"
                />
              </div>
            </div>
          </vue-perfect-scrollbar>
        </b-col>
        <b-col
          sm="4"
          cols="12"
          class="d-flex centralizar-mobile text-center"
        >
          <div>
            <div class="mb-1">
              <h6>Escolha um filtro</h6>
            </div>
            <div
              class="d-flex justify-content-center align-content-center"
            >
              <v-select
                v-model="opcaoSelecionada"
                @input="atualizaTipoSelecionado"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="opcoesSelect"
                :clearable="false"
                class="select-custom-filtros"
                label="descricao"
              >
                <span slot="no-options">Nenhum filtro encontrado.</span>
              </v-select>
            </div>
            <div
              class="d-flex justify-content-center align-items-center mt-2 mb-sm-0 mb-2"
            >
              <div
                class="grouping-escalas"
              >
                <div class="align-by-line">
                  <div
                    class="spacement-bullet"
                  >
                    <div
                      class="bullet-escala bullet-color-ansiedade"
                    ></div>
                  </div>
                  <div
                    class="spacement-label"
                  >
                    <div class="item-escala">Ansiedade</div>
                  </div>
                </div>
                <div class="align-by-line">
                  <div
                    class="spacement-bullet"
                  >
                    <div
                      class="bullet-escala bullet-color-depressao"
                    ></div>
                  </div>
                  <div
                    class="spacement-label"
                  >
                    <div class="item-escala">Depressão</div>
                  </div>
                </div>
                <div class="align-by-line">
                  <div
                    class="spacement-bullet"
                  >
                    <div
                      class="bullet-escala bullet-color-estresse"
                    ></div>
                  </div>
                  <div
                    class="spacement-label"
                  >
                    <div class="item-escala">Estresse</div>
                  </div>
                </div>
                <div class="align-by-line">
                  <div
                    class="spacement-bullet"
                  >
                    <div
                      class="bullet-escala bullet-color-sem-sintomas"
                    ></div>
                  </div>
                  <div
                    class="spacement-label"
                  >
                    <div class="item-escala">Sem sintomas</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay
} from "bootstrap-vue";
import DetalhamentoPorAreaGrafico from "@/views/components/custom/dashboard-saude-mental/dass-21/DetalhamentoPorAreaGrafico";
import DetalhamentoPorAreaGraficoPizza from "@/views/components/custom/dashboard-saude-mental/dass-21/DetalhamentoPorAreaGraficoPizza";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";

export default {
  components: {
    DetalhamentoPorAreaGrafico,
    DetalhamentoPorAreaGraficoPizza,
    VuePerfectScrollbar,
    vSelect,
    BRow,
    BCol,
    BOverlay,
    CardSemDados
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loadingShow: true,
      childKey: 0,
      opcaoSelecionada: null,
      opcoesSelect: [
        "Total",
        "Setores",
        "Gerência",
        "Líderes"
      ],
      dadosSelecionados: {},
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0
      }
    };
  },
  mounted() { 
    this.opcaoSelecionada = this.opcoesSelect[0]
    this.dadosSelecionados = this.$props.dados[0]
    this.loadingShow = false
  },
  methods: {
    atualizaTipoSelecionado(tipoString){
      const indexSelecionado = this.opcoesSelect.indexOf(tipoString)
      this.dadosSelecionados = this.$props.dados[indexSelecionado]
      this.childKey++
    }
  }
};
</script>

<style scoped>
.height-custom{
  height:240px;
}
.bullet-color-ansiedade{
  background-color: #2772C0;
}
.bullet-color-depressao{
  background-color: #002060;
}
.bullet-color-estresse{
  background-color: #7030A0;
}
.bullet-color-sem-sintomas{
  background-color: #36BA00;
}
.grouping-escalas{
  font-size: 10px;
  font-weight: 500;
}
.align-by-line{
  display: flex;
  align-items: center;
  height: 15px;
  margin-bottom: 5px;
}
.spacement-bullet{
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.bullet-escala{
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.spacement-label{
  height: 100%;
  display: flex;
  align-items: center;
}
.item-escala{
  text-align: initial;
  min-width: 74px;
}
.select-custom-filtros{
  min-width: 97px;
}
.select-custom-filtros::v-deep .vs__dropdown-toggle{
  border: 1px solid #7030A0;
  justify-content: center;
  align-items: center;
}
.select-custom-filtros::v-deep .vs__selected-options{
  padding: 0;
  flex-basis: inherit;
  flex-grow: initial;
  padding-left: 5px;
  padding-top: 2px;
}
.select-custom-filtros::v-deep .vs__search{
  margin: 0;
  padding: 0;
  max-height: 0;
  max-width: 0;
}
.select-custom-filtros::v-deep .vs__selected{
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #7030A0 !important;
  padding-right: 3px;
}
.select-custom-filtros.vs--single.vs--open::v-deep .vs__selected{
  position: relative;
}
.select-custom-filtros::v-deep .feather-chevron-down{
  color: #7030A0;
}
.minimal-graph-size-pizza{
  height: 100%;
  width: 100%;
  max-height: 200px;
  max-width: 200px;
}
@media (min-width: 1600px) {
  .select-custom-filtros::v-deep .vs__selected{
    font-size: 13px;
  }
  .grouping-escalas{
    font-size: 12px;
  }
}
@media(max-width: 575px){
  .centralizar-mobile{
    justify-content: center;
  }
}
</style>
